<template>
  <vDialog :show.sync="visible" :visibleFooter="false" top="10%">
    <el-row class="mb-4" type="flex" align="middle">
      <i class="el-icon-warning-outline text-magenta"></i>
      <div class="ms-2">
        <h6 class="title">{{ $t('sessionDialog.title') }}</h6>
        <p>{{ $t('sessionDialog.content') }}</p>
      </div>
    </el-row>
    <el-row type="flex" justify="end">
      <el-button type="primary" size="medium" @click="keepIn" data-testid="keepIn">{{
        $t('sessionDialog.keepIn')
      }}</el-button>
      <el-button type="text" size="medium" plain @click="signOut" data-testid="signOut">{{
        $t('sessionDialog.signOut')
      }}</el-button>
    </el-row>
  </vDialog>
</template>

<script>
import vDialog from '@/components/vDialog.vue';
import { setCookies, getCookies, getExpiresTime } from '@/util/cookies';
import { minusTime } from '@/util/time';
import helper from '@/util/signinHelper';

export default {
  components: { vDialog },
  data() {
    return {
      visible: false,
      alertSec: 60 * 1000,
      countDown: null,
      totalTime: null,
      signOutTimer: null
    };
  },
  mounted() {
    this.inital();
  },
  methods: {
    inital() {
      this.setTime(this.getTotalTime() - this.alertSec, this.timeoutFunc);
    },
    timeoutFunc() {
      this.countDown = 60;
      this.signOutTimer = this.setInter(1000, this.intervalFunc);
      this.visible = true;
    },
    intervalFunc() {
      this.countDown >= 0 ? this.countDown-- : this.signOut();
    },
    setTime(time, func) {
      return setTimeout(() => {
        func();
      }, time);
    },
    setInter(time, func) {
      return setInterval(() => {
        func();
      }, time);
    },
    getTotalTime() {
      return minusTime(new Date(), getExpiresTime('token'));
    },
    keepIn() {
      if (!getCookies('token')) return this.signOut();
      
      const token = getCookies('token');
      setCookies('token', token, 30);
      this.setTime(this.getTotalTime() - this.alertSec, this.timeoutFunc);
      clearInterval(this.signOutTimer);
      this.visible = false;
    },
    signOut() {
      this.visible = false;
      clearInterval(this.signOutTimer);
      helper.signOut(null,'idleSessionDialog');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/components/sessionDialog.scss';
</style>
