export default {
  methods: {
    proTpPremiumReplacerPartial(val, type) {
      if (this.regulator === 'asic') {
        return type === 1
          ? val.replace(/\b(Pro)\b/gi, 'Premium').replace(/Video/gi, '')
          : val.replace(/\b(PRO)\b/gi, 'PREMIUM').replace(/VIDEO/gi, '')
      } else {
        return val
      }
    },
  },
  computed: {
    lang: {
      get() {
        return this.$store.state.common.lang
      },
      set(value) {
        return this.$store.commit('common/setLang', value)
      },
    },
  },
}
