<template>
  <div class="swiper_box">
    <el-carousel trigger="click" height="255px" indicator-position="none" :interval="interval">
      <el-carousel-item
        v-for="(item, index) in banners"
        @click.native="clickAllUrl(item.allUrl)"
        :class="{ specificClass: item.allUrl }"
        :key="index"
        :style="{
          backgroundImage: 'url(' + item.img + ')',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover'
        }"
      >
        <div class="info" :class="({ paddingTop: item.divInfo }, item.extraClass)">
          <div v-html="$t(item.divInfo)" class="specificInfo" v-if="item.divInfo"></div>
          <h2 v-if="item.info" :class="{ withoutButtonClass: !item.button }">
            {{ item.info }}
          </h2>
          <div class="right" v-if="item.rightInfo">
            <p class="rightInfo" v-html="item.rightInfo"></p>
            <a v-if="item.rightButton" class="rightButton" :href="item.url" :target="item.target || '_blank'">
              {{ item.rightButton }}
            </a>
          </div>
          <a :href="item.url" :target="item.target || '_blank'" v-if="item.button">
            {{ item.button }}
          </a>
          <a :href="item.url" :target="item.target || '_blank'" v-if="item.specificButton" class="specificButton">
            {{ item.specificButton }}
          </a>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      interval: 10000
    };
  },
  methods: {
    clickAllUrl(url) {
      if (url) window.open(url);
    }
  },
  computed: {
    banners() {
      // console.log('object', this.$platform.banner(this.regulator));
      return this.$platform
        .banner(this.regulator, this.GLOBAL_DOMAIN_WEBSITE)
        .filter(x =>
          x.excludeCountries ? !x.excludeCountries.includes(parseInt(this.$store.state.common.countryCode)) : x
        );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/banner.scss';
</style>
