<template>
  <div id="menu">
    <div class="menu_shadow" v-show="matches && onOpen" @click="matchesClose"></div>
    <div class="menu_wrapper" :style="menu_wrapperStyle">
      <div class="menu_logo" v-if="(!isCollapse || matches) && onOpen">
        <a href="https://www.monetamarkets.com/" target="_blank">
          <img alt class="logoImg" />
        </a>
        <div>
          <img alt class="client_portal" src="@/assets/images/mo/client_portal.png" />
        </div>
      </div>
      <div class="menu_logo_small" v-else>
        <a href="https://www.monetamarkets.com/" target="_blank">
          <img alt class="logoImg" />
        </a>
      </div>
      <el-scrollbar style="height: 100%">
        <el-collapse-transition>
          <el-menu
            class="el-menu-vertical-demo"
            :background-color="$platform.style.menuBackground"
            :text-color="$platform.style.menuTextColor"
            :active-text-color="$platform.style.menuActiveTextColor"
            :unique-opened="true"
            :default-active="$route.path"
            :collapse="isCollapse && !matches"
            data-testid="menu"
          >
            <div v-for="(menu, index) in filteredMenus" v-bind:key="index">
              <el-menu-item
                v-if="checkRegister(menu)"
                :index="checkHomeUrl(menu.path)"
                :data-testid="menu.name"
                @click="clickMenu(menu)"
              >
                <img :src="menu.icon" class="icon" alt="" />
                <div :class="!isCollapse ? 'd-inline' : 'd-none'">
                  <span
                    slot="title"
                    @click="menuClick(menu.path)"
                    v-html="proTpPremiumReplacerPartial($t(menu.name), 2)"
                    :data-testid="`click_${menu.path}`"
                  >
                  </span>
                  <img v-if="menu.iconSuffix" :src="menu.iconSuffix" alt="image" />
                </div>
                <span
                  slot="title"
                  v-show="isCollapse"
                  @click="menuClick(menu.path)"
                  v-html="proTpPremiumReplacerPartial($t(menu.name), 2)"
                >
                </span>
                <div class="line"></div>
              </el-menu-item>
              <el-submenu :index="menu.path" v-if="menu.children" :data-testid="menu.name">
                <template slot="title">
                  <img :src="menu.icon" class="icon" alt="" />
                  <span slot="title" v-html="proTpPremiumReplacerPartial($t(menu.name), 2)"></span>
                  <div class="line"></div>
                </template>
                <template v-for="(children, index) in menu.children">
                  <el-menu-item
                    v-if="isOptional(children)"
                    :key="index"
                    :index="children.path"
                    :data-testid="children.name"
                    @click="clickMenu(children)"
                  >
                    {{ $t(children.name) }}
                    <img v-if="children.iconSuffix" :src="children.iconSuffix" alt="image" />
                    <div class="line"></div>
                  </el-menu-item>
                </template>
              </el-submenu>
            </div>
            <component
              :userTypeEnum="userTypeEnum"
              :is="whloesaleComponent"
              v-if="!isCollapse || matches"
              @goToUpgradeToPro="goToUpgradeToPro"
            ></component>
            <a
              v-if="userTypeEnum == 'IB' && (!isCollapse || matches)"
              class="swith_to"
              @click.stop.prevent="$redirect.redirectToIbPortal()"
              data-testid="swith_to"
              >{{ $t('common.button.goToIB') }}</a
            >
          </el-menu>
        </el-collapse-transition>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import openAccountMixin from '@/mixins/openAccount';
import asicMixin from '@/mixins/proTools/asicReplacer';
import blackList from '@/mixins/blackList';

export default {
  name: 'MenuTemplate',
  mixins: [openAccountMixin, asicMixin, blackList],
  props: { isCollapse: Boolean, matches: Boolean, onOpen: Boolean },
  data() {
    return {
      bodyDirection: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.bodyDirection = document.body.dir;
    });
    this.checkCampaignEligibility();
  },
  watch: {
    $route(value) {
      if (value.path === '/home') {
        this.checkCampaignEligibility();
      }
    }
  },
  methods: {
    matchesClose() {
      this.$emit('matchesClose');
    },
    checkMatchesClose() {
      const vm = this;
      this.$nextTick(function () {
        if (vm.matches) vm.matchesClose();
      });
    },
    clickMenu(children) {
      this.checkMatchesClose();
      if (!children.hasOwnProperty('checkBlacklist')) {
        this.$router.push({ path: children.path }).catch(err => {});
      } else {
        if (children.path === '/depositFunds') this.getDepositBlacklist();
        else if (children.path === '/transferFunds') this.goToTransferFunds();
        else this.getWithdrawalBlacklist();
      }
    },
    isOptional(children) {
      if (!children.hasOwnProperty('optional')) return children;
      else {
        // openAccountMixin
        if (this.openAdditionAccountEnabled) return children;
      }
    },
    checkRegister(menu) {
      return (menu.path != '/register' || this.registerStep < 6) && !menu.children;
    },
    checkHomeUrl(path) {
      return path == '/home' && this.registerStep == 1 && this.isDemo ? '/homeDemo' : path;
    },
    menuClick(url) {
      if (this.isCollapse) this.$router.push({ path: url }).catch(err => {});
    },
    checkCampaignEligibility() {
      this.$store.dispatch('promotion/actionGetEligibleCampaigns');
    },
    goToUpgradeToPro() {
      this.checkMatchesClose();
      this.$router.push({ path: '/upgradeToPro' }).catch(err => {});
    }
  },
  computed: {
    registerStep() {
      return this.$store.state.common.registerStep;
    },
    isDemo() {
      return this.$store.state.common.isDemo;
    },
    userTypeEnum() {
      return this.$store.state.common.userTypeEnum;
    },
    menu_wrapperStyle() {
      const menuWidth = { width: this.matches == false && this.isCollapse ? '64px' : '15rem' };
      const direction = this.bodyDirection === 'rtl' ? { right: this.menuPosition } : { left: this.menuPosition };
      return { ...direction, ...menuWidth };
    },
    menuPosition() {
      return this.matches && !this.onOpen ? '-15rem' : '0px';
    },
    whloesaleComponent() {
      return () => this.$platform.componentLoader(this.regulator, 'menu/UpgradeToProCTA', true).component;
    },
    campaignEligibility() {
      return this.$store.state.promotion.eligibleCampaigns;
    },
    filteredMenus() {
      const availableCampaign = [];
      const campaignExist = campaignType =>
        this.campaignEligibility.some(campaign => campaign.campaignType === campaignType);
      if (campaignExist('CASHBACK_2')) {
        availableCampaign.push('menu.cashback');
      }

      if (availableCampaign.length === 0) {
        return this.$platform.getMenus(this.regulator).filter(child => !['menu.promotion'].includes(child.name));
      }

      if (availableCampaign.length > 0) {
        const fullMenu = this.$platform.getMenus(this.regulator);
        fullMenu.forEach(menu => {
          if (menu.name === 'menu.promotion') {
            menu.children = menu.children.filter(child => availableCampaign.includes(child.name));
          }
        });
        return fullMenu;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/menu.scss';
</style>
