<template>
  <el-container :key="reRender" class="wrapper">
    <el-aside v-show="showMenu" :width="!matches ? center : ''">
      <slideBar
        :isCollapse.sync="isCollapse"
        :matches="matches"
        :onOpen.sync="onOpen"
        @matchesClose="matchesClose"
      ></slideBar>
    </el-aside>
    <el-container class="is-vertical">
      <vHeader :matches="matches" :showMenu="showMenu" @openMenu="openMenu"></vHeader>
      <el-main id="elMain">
        <Banner v-if="showBanner"></Banner>
        <transition>
          <router-view></router-view>
        </transition>
        <SessionDialog />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import vHeader from '@/components/template/header/Header';
import slideBar from '@/components/Menu';
import Banner from '@/components/Banner';
import SessionDialog from '@/components/SessionDialog.vue';
import { apiSetlanguage } from '@/resource';
import i18n from '@/lang';

export default {
  data() {
    return {
      reRender: 0,
      center: '15rem',
      matches: false,
      showMenu: false,
      isCollapse: false,
      showBanner: false,
      onOpen: true
    };
  },
  components: { vHeader, slideBar, Banner, SessionDialog },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  watch: {
    lang: {
      immediate: true,
      async handler(val) {
        this.$i18n.locale = val;
        if (this.$store.state.common.loginStatus) await this.setUserLanguagePreference(val);
        this.reRender++;
        this.limitLang();
      }
    },
    reRender(reRender) {
      if (this.$route.meta.restrictReload) this.$router.push('/home');
    },
    onOpen(bool) {
      this.isCollapse = !bool;
    },
    isCollapse(bool) {
      if (!bool && this.onOpen) this.center = '15rem';
      else if (!this.matches) this.center = '64px';
      else this.center = '0';
    },
    matches(bool) {
      if (bool) {
        this.onOpen = false;
        this.center = '0';
        this.isCollapse = false;
      } else {
        this.onOpen = true;
        this.center = '15rem';
      }
    },
    $route: {
      handler: function (route) {
        this.limitLang();
        this.showMenu = route.meta.allowAnonymous ? false : true;
        this.showBanner = route.meta.restrictBanner ? false : true;
        this.$nextTick(() => {
          const elMain = document.getElementById('elMain');
          elMain.scrollTop = 0;
        });
      },
      immediate: true
    }
  },
  mounted() {
    const vm = this;
    this.matches = window.matchMedia('(max-width: 1024px)').matches;
    $(window).resize(function () {
      vm.matches = window.matchMedia('(max-width: 1024px)').matches;
    });

    const hostname = location.hostname;
    if (!hostname.includes('.hk')) {
      var tag = document.createElement('script');

      tag.src = 'https://www.youtube.com/iframe_api';
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  },
  methods: {
    limitLang() {
      i18n.locale = this.$route.meta.limitLang || this.lang;
    },
    async setUserLanguagePreference(lang) {
      await apiSetlanguage({ language: lang });
    },
    matchesClose() {
      this.onOpen = !this.onOpen;
      this.isCollapse = false;
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
      this.onOpen = !this.onOpen;
    }
  }
};
</script>
<style lang="scss">
@import '@/assets/css/app.scss';
.v-leave {
  opacity: 1;
}
.v-leave-active {
  transition: opacity 0.5s;
}
.v-leave-to {
  opacity: 0;
}
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 0.2s;
}
.v-enter-to {
  opacity: 1;
}
</style>
